.container,
.container-full {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px;
}

.container .grid,
.container-full .grid {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 576px) {
  .container {
    max-width: 576px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
}

.grid {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 auto;
  position: relative;
  letter-spacing: -.31em;
  *letter-spacing: normal;
  word-spacing: -.43em;
  list-style-type: none;
}

.grid::before, .grid::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

[class*="col-"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
  position: relative;
  width: 100%;
  vertical-align: top;
  padding: 15px;
  display: inline-block;
  *display: inline;
  zoom: 1;
}

[class*="col-"]::before, [class*="col-"]::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}

[class*="col-"] .grid {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  margin: -15px;
}

.col-12 {
  width: 100%;
  *width: 99.9%;
}

.col-11 {
  width: 91.66666667%;
  *width: 91.56666667%;
}

.col-10 {
  width: 83.33333333%;
  *width: 83.23333333%;
}

.col-9 {
  width: 75%;
  *width: 74.9%;
}

.col-8 {
  width: 66.66666667%;
  *width: 66.56666667%;
}

.col-7 {
  width: 58.33333333%;
  *width: 58.23333333%;
}

.col-6 {
  width: 50%;
  *width: 49.9%;
}

.col-5 {
  width: 41.66666667%;
  *width: 41.56666667%;
}

.col-4 {
  width: 33.33333333%;
  *width: 33.23333333%;
}

.col-3 {
  width: 25%;
  *width: 24.9%;
}

.col-2 {
  width: 16.66666667%;
  *width: 16.56666667%;
}

.col-1 {
  width: 8.33333333%;
  *width: 8.23333333%;
}

@media (min-width: 576px) {
  .col-xs-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-xs-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-xs-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-xs-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-xs-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-xs-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-xs-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-xs-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-xs-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-xs-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-xs-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-xs-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (min-width: 768px) {
  .col-sm-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-sm-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-sm-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-sm-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-sm-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-sm-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-sm-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-sm-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (min-width: 992px) {
  .col-md-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-md-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-md-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-md-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-md-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-md-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-md-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-md-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (min-width: 1200px) {
  .col-lg-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-lg-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-lg-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-lg-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-lg-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-lg-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-lg-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-lg-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

@media (min-width: 1600px) {
  .col-xlg-12 {
    width: 100%;
    *width: 99.9%;
  }
  .col-xlg-11 {
    width: 91.66666667%;
    *width: 91.56666667%;
  }
  .col-xlg-10 {
    width: 83.33333333%;
    *width: 83.23333333%;
  }
  .col-xlg-9 {
    width: 75%;
    *width: 74.9%;
  }
  .col-xlg-8 {
    width: 66.66666667%;
    *width: 66.56666667%;
  }
  .col-xlg-7 {
    width: 58.33333333%;
    *width: 58.23333333%;
  }
  .col-xlg-6 {
    width: 50%;
    *width: 49.9%;
  }
  .col-xlg-5 {
    width: 41.66666667%;
    *width: 41.56666667%;
  }
  .col-xlg-4 {
    width: 33.33333333%;
    *width: 33.23333333%;
  }
  .col-xlg-3 {
    width: 25%;
    *width: 24.9%;
  }
  .col-xlg-2 {
    width: 16.66666667%;
    *width: 16.56666667%;
  }
  .col-xlg-1 {
    width: 8.33333333%;
    *width: 8.23333333%;
  }
}

.col-auto {
  -ms-flex: 1 0 auto;
  -webkit-flex: 1 0 0px;
  flex: 1 0 0px;
  width: auto;
}

@media (min-width: 576px) {
  .col-xs-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}

@media (min-width: 768px) {
  .col-sm-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}

@media (min-width: 992px) {
  .col-md-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}

@media (min-width: 1200px) {
  .col-lg-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}

@media (min-width: 1600px) {
  .col-xlg-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto;
  }
}

.order-12 {
  -ms-flex-order: 12;
  -webkit-order: 12;
  order: 12;
}

.order-11 {
  -ms-flex-order: 11;
  -webkit-order: 11;
  order: 11;
}

.order-10 {
  -ms-flex-order: 10;
  -webkit-order: 10;
  order: 10;
}

.order-9 {
  -ms-flex-order: 9;
  -webkit-order: 9;
  order: 9;
}

.order-8 {
  -ms-flex-order: 8;
  -webkit-order: 8;
  order: 8;
}

.order-7 {
  -ms-flex-order: 7;
  -webkit-order: 7;
  order: 7;
}

.order-6 {
  -ms-flex-order: 6;
  -webkit-order: 6;
  order: 6;
}

.order-5 {
  -ms-flex-order: 5;
  -webkit-order: 5;
  order: 5;
}

.order-4 {
  -ms-flex-order: 4;
  -webkit-order: 4;
  order: 4;
}

.order-3 {
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}

.order-2 {
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}

.order-1 {
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}

.order-0 {
  -ms-flex-order: 0;
  -webkit-order: 0;
  order: 0;
}

@media only screen and (min-width: 576px) {
  .order-12-xs {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .order-11-xs {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .order-10-xs {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .order-9-xs {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .order-8-xs {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .order-7-xs {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .order-6-xs {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .order-5-xs {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .order-4-xs {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .order-3-xs {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .order-2-xs {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .order-1-xs {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .order-0-xs {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}

@media only screen and (min-width: 768px) {
  .order-12-sm {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .order-11-sm {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .order-10-sm {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .order-9-sm {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .order-8-sm {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .order-7-sm {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .order-6-sm {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .order-5-sm {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .order-4-sm {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .order-3-sm {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .order-2-sm {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .order-1-sm {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .order-0-sm {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}

@media only screen and (min-width: 992px) {
  .order-12-md {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .order-11-md {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .order-10-md {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .order-9-md {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .order-8-md {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .order-7-md {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .order-6-md {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .order-5-md {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .order-4-md {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .order-3-md {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .order-2-md {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .order-1-md {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .order-0-md {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .order-12-lg {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .order-11-lg {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .order-10-lg {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .order-9-lg {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .order-8-lg {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .order-7-lg {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .order-6-lg {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .order-5-lg {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .order-4-lg {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .order-3-lg {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .order-2-lg {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .order-1-lg {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .order-0-lg {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}

@media only screen and (min-width: 1600px) {
  .order-12-xlg {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .order-11-xlg {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .order-10-xlg {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .order-9-xlg {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .order-8-xlg {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .order-7-xlg {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .order-6-xlg {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .order-5-xlg {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .order-4-xlg {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .order-3-xlg {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .order-2-xlg {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .order-1-xlg {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .order-0-xlg {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}

.offset-11 {
  margin-left: 91.66666667%;
  *margin-left: 91.56666667%;
}

.offset-10 {
  margin-left: 83.33333333%;
  *margin-left: 83.23333333%;
}

.offset-9 {
  margin-left: 75%;
  *margin-left: 74.9%;
}

.offset-8 {
  margin-left: 66.66666667%;
  *margin-left: 66.56666667%;
}

.offset-7 {
  margin-left: 58.33333333%;
  *margin-left: 58.23333333%;
}

.offset-6 {
  margin-left: 50%;
  *margin-left: 49.9%;
}

.offset-5 {
  margin-left: 41.66666667%;
  *margin-left: 41.56666667%;
}

.offset-4 {
  margin-left: 33.33333333%;
  *margin-left: 33.23333333%;
}

.offset-3 {
  margin-left: 25%;
  *margin-left: 24.9%;
}

.offset-2 {
  margin-left: 16.66666667%;
  *margin-left: 16.56666667%;
}

.offset-1 {
  margin-left: 8.33333333%;
  *margin-left: 8.23333333%;
}

@media only screen and (min-width: 576px) {
  .offset-11-xs {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }
  .offset-10-xs {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }
  .offset-9-xs {
    margin-left: 75%;
    *margin-left: 74.9%;
  }
  .offset-8-xs {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }
  .offset-7-xs {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }
  .offset-6-xs {
    margin-left: 50%;
    *margin-left: 49.9%;
  }
  .offset-5-xs {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }
  .offset-4-xs {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }
  .offset-3-xs {
    margin-left: 25%;
    *margin-left: 24.9%;
  }
  .offset-2-xs {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }
  .offset-1-xs {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }
  .offset-0-xs {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media only screen and (min-width: 768px) {
  .offset-11-sm {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }
  .offset-10-sm {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }
  .offset-9-sm {
    margin-left: 75%;
    *margin-left: 74.9%;
  }
  .offset-8-sm {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }
  .offset-7-sm {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }
  .offset-6-sm {
    margin-left: 50%;
    *margin-left: 49.9%;
  }
  .offset-5-sm {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }
  .offset-4-sm {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }
  .offset-3-sm {
    margin-left: 25%;
    *margin-left: 24.9%;
  }
  .offset-2-sm {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }
  .offset-1-sm {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }
  .offset-0-sm {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media only screen and (min-width: 992px) {
  .offset-11-md {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }
  .offset-10-md {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }
  .offset-9-md {
    margin-left: 75%;
    *margin-left: 74.9%;
  }
  .offset-8-md {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }
  .offset-7-md {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }
  .offset-6-md {
    margin-left: 50%;
    *margin-left: 49.9%;
  }
  .offset-5-md {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }
  .offset-4-md {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }
  .offset-3-md {
    margin-left: 25%;
    *margin-left: 24.9%;
  }
  .offset-2-md {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }
  .offset-1-md {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }
  .offset-0-md {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media only screen and (min-width: 1200px) {
  .offset-11-lg {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }
  .offset-10-lg {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }
  .offset-9-lg {
    margin-left: 75%;
    *margin-left: 74.9%;
  }
  .offset-8-lg {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }
  .offset-7-lg {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }
  .offset-6-lg {
    margin-left: 50%;
    *margin-left: 49.9%;
  }
  .offset-5-lg {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }
  .offset-4-lg {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }
  .offset-3-lg {
    margin-left: 25%;
    *margin-left: 24.9%;
  }
  .offset-2-lg {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }
  .offset-1-lg {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }
  .offset-0-lg {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

@media only screen and (min-width: 1600px) {
  .offset-11-xlg {
    margin-left: 91.66666667%;
    *margin-left: 91.56666667%;
  }
  .offset-10-xlg {
    margin-left: 83.33333333%;
    *margin-left: 83.23333333%;
  }
  .offset-9-xlg {
    margin-left: 75%;
    *margin-left: 74.9%;
  }
  .offset-8-xlg {
    margin-left: 66.66666667%;
    *margin-left: 66.56666667%;
  }
  .offset-7-xlg {
    margin-left: 58.33333333%;
    *margin-left: 58.23333333%;
  }
  .offset-6-xlg {
    margin-left: 50%;
    *margin-left: 49.9%;
  }
  .offset-5-xlg {
    margin-left: 41.66666667%;
    *margin-left: 41.56666667%;
  }
  .offset-4-xlg {
    margin-left: 33.33333333%;
    *margin-left: 33.23333333%;
  }
  .offset-3-xlg {
    margin-left: 25%;
    *margin-left: 24.9%;
  }
  .offset-2-xlg {
    margin-left: 16.66666667%;
    *margin-left: 16.56666667%;
  }
  .offset-1-xlg {
    margin-left: 8.33333333%;
    *margin-left: 8.23333333%;
  }
  .offset-0-xlg {
    margin-left: 0;
    *margin-left: -0.1%;
  }
}

.wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.no-wrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.no-wrap [class*="col-"] {
  -ms-flex-negative: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
}

.wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.direction-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.direction-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.direction-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.align-start {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.align-end {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.align-end [class*="col-"] {
  vertical-align: bottom;
}

.align-center {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.align-center [class*="col-"] {
  vertical-align: middle;
}

.align-baseline {
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.align-content-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

.align-content-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}

.align-content-end [class*="col-"] {
  vertical-align: bottom;
}

.align-content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}

.align-content-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between;
}

.align-content-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around;
}

.align-self-stretch {
  -ms-flex-item-align: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}

.align-self-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.align-self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  vertical-align: bottom;
}

.align-self-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  vertical-align: middle;
}

.align-self-baseline {
  -ms-flex-item-align: baseline;
  -webkit-align-self: baseline;
  align-self: baseline;
  vertical-align: baseline;
}

.justify-start {
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.justify-start.grid {
  text-align: left;
}

.justify-end {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.justify-end.grid {
  text-align: right;
  -moz-text-align-last: right;
  text-align-last: right;
}

.justify-end.grid [class*="col-"] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.justify-center.grid {
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
}

.justify-center.grid [class*="col-"] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.justify-space-between.grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}

.justify-space-between.grid [class*="col-"] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.justify-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.justify-space-around.grid {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
}

.justify-space-around.grid [class*="col-"] {
  text-align: left;
  text-align: start;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  text-align-last: left;
  text-align-last: start;
}

.grid-bleed [class*="col-"] {
  padding: 0;
}

.col-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.col-grid.direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.col-bleed {
  padding: 0;
}

.col-bleed-x {
  padding: 15px 0;
}

.col-bleed-y {
  padding: 0 15px;
}

.flex-img {
  display: block;
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  max-width: 100%;
  height: auto;
  width: 100%;
  *width: auto;
}

.flex-footer {
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
}

.flex-footer > :last-child {
  margin-bottom: 0;
}

@media (max-width: 575px) {
  .hidden-xxs {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .hidden-lg {
    display: none;
  }
}

@media (min-width: 1600px) {
  .hidden-xlg {
    display: none;
  }
}
